import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Button, Paper } from '@material-ui/core'
import { Box } from '@material-ui/system'
import { useState } from 'react'

import styles from './DragAndDrop.module.scss'

interface IChoice {
  id: number
  text: string
  parent?: string
}

const buttonText = {
  NEXT: 'Weiter',
  RESTART: 'Quiz nochmal starten',
}

const DragAndDrop = () => {
  const [choices, setChoices] = useState<IChoice[]>([
    { id: 0, text: 'Ökosystemen', parent: 'A' },
    { id: 1, text: 'individuellen Experten' },
    { id: 2, text: 'mittel- bis langfristige', parent: 'B' },
    { id: 3, text: 'kurz- bis mittelfristige' },
  ])

  const [parents, setParents] = useState<(any | null)[]>([
    null,
    null,
    null,
    null,
  ])

  const [showFeedback, setShowFeedback] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [question, setQuestion] = useState(0)
  const [answeredCorrectly, setAnsweredCorrectly] = useState(false)
  const [button, setButton] = useState(buttonText.NEXT)
  // const draggableMarkup = <Draggable id='0'>Drag me</Draggable>

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    // console.log(event)

    // If the item is dropped over a container, set it as the parent
    // otherwise reset the parent to `null`
    const newParents = [...parents]
    if (newParents.includes(over?.id)) {
      const index = newParents.indexOf(over.id)
      newParents[index] = null
    }
    const int = parseInt(active.id) ?? 0
    newParents[int] = over ? over.id : null
    setParents(newParents)
    // console.log(parents)
    // setParents(over ? over.id : null)
  }

  const getDraggable = (index: number) => {
    return (
      <Draggable key={index} id={`${index}`}>
        {choices[index].text}
      </Draggable>
    )
  }

  const handleNext = () => {
    if (question === 3) {
      setButton(buttonText.NEXT)
      setChoices([
        { id: 0, text: 'Ökosystemen', parent: 'A' },
        { id: 1, text: 'individuellen Experten' },
        { id: 2, text: 'mittel- bis langfristige', parent: 'B' },
        { id: 3, text: 'kurz- bis mittelfristige' },
      ])
      setQuestion(0)
      setAnsweredCorrectly(false)
      setShowFeedback(false)
      return setParents([null, null, null, null])
    }
    if (answeredCorrectly) return showNextQuestion()
    const isCorrect = checkAnswers()
    if (!isCorrect) {
      setFeedback('Leider falsch.')
      setAnsweredCorrectly(false)
    } else {
      setFeedback('Richtig!')
      setAnsweredCorrectly(true)
    }
    setShowFeedback(true)
  }

  const showNextQuestion = () => {
    setQuestion((previous) => {
      switch (previous) {
        case 0:
          setChoices([
            { id: 0, text: 'Etablierung', parent: 'A' },
            { id: 1, text: 'Ausgliederung' },
            { id: 2, text: 'funktioniert' },
            { id: 3, text: 'nicht funktioniert', parent: 'B' },
          ])
          break
        case 1:
          setChoices([
            { id: 0, text: 'Vorteil' },
            { id: 1, text: 'Nachteil', parent: 'A' },
            { id: 2, text: 'Wissensmanagement', parent: 'B' },
            { id: 3, text: 'Binden der externen Ressource' },
          ])
          break
        case 2:
          setButton(buttonText.RESTART)
          break
        default:
          break
      }
      return previous + 1
    })
    setAnsweredCorrectly(false)
    setShowFeedback(false)
    setParents([null, null, null, null])
  }

  const checkAnswers = () => {
    let correct: boolean[] = []
    choices.forEach((choice, index) => {
      if (choice.parent) correct.push(choice.parent === parents[index])
    })
    return !correct.includes(false)
  }

  const getQuestion = (index: number) => {
    switch (index) {
      case 0:
        return (
          <>
            <span className={styles.questionText}>Der Aufbau von</span>
            <Droppable key={'A'} id={'A'}>
              {parents.map((parent, idx) =>
                parent === 'A' ? getDraggable(idx) : null
              )}
            </Droppable>
            <span className={styles.questionText}>
              als Teil der eigenen digitalen Transformation eignet sich
              besonders gut für die
            </span>
            <Droppable key={'B'} id={'B'}>
              {parents.map((parent, idx) =>
                parent === 'B' ? getDraggable(idx) : null
              )}
            </Droppable>
            <span className={styles.questionText}>Kompetenzerweiterung.</span>
          </>
        )
      case 1:
        return (
          <>
            <span className={styles.questionText}>Die</span>
            <Droppable key={'A'} id={'A'}>
              {parents.map((parent, idx) =>
                parent === 'A' ? getDraggable(idx) : null
              )}
            </Droppable>
            <span className={styles.questionText}>
              eines eigenen Start-Ups rentiert sich, wenn der interne
              Kompetenzaufbau im Rahmen der bestehenden Unternehmenskultur
              bisher
            </span>
            <Droppable key={'B'} id={'B'}>
              {parents.map((parent, idx) =>
                parent === 'B' ? getDraggable(idx) : null
              )}
            </Droppable>
            <span className={styles.questionText}>
              hat und man das Thema als strategisch wichtig erachtet.
            </span>
          </>
        )

      case 2:
        return (
          <>
            <span className={styles.questionText}>
              Extern zugekaufte digitale Kompetenzen haben den
            </span>
            <Droppable key={'A'} id={'A'}>
              {parents.map((parent, idx) =>
                parent === 'A' ? getDraggable(idx) : null
              )}
            </Droppable>
            <span className={styles.questionText}>
              , dass erarbeitetes Wissen nach einem Projekt die Firma wieder
              verlässt. Gutes
            </span>
            <Droppable key={'B'} id={'B'}>
              {parents.map((parent, idx) =>
                parent === 'B' ? getDraggable(idx) : null
              )}
            </Droppable>
            <span className={styles.questionText}>
              ist hier der Schlüssel zu nachhaltiger Weiterentwicklung des
              Unternehmens.
            </span>
          </>
        )

      default:
        break
    }
  }

  return (
    <div>
      <h1>
        Vervollständigen Sie bitte nachfolgende Lückentexte mit den richtigen
        Wörtern:
      </h1>
      <DndContext onDragEnd={handleDragEnd}>
        <Paper
          elevation={4}
          sx={{
            padding: '1rem',
            lineHeight: '1.4',
            // maxWidth: '80ch',
            margin: '1rem',
          }}>
          <div className={styles.questionContainer}>
            {question <= 2 ? (
              getQuestion(question)
            ) : (
              <p style={{ textAlign: 'center' }}>
                Vielen Dank für Ihre Teilnahme!
              </p>
            )}
          </div>
          {question <= 2 && (
            <div className={styles.choices}>
              {parents.map((parent, index) =>
                parent === null ? getDraggable(index) : null
              )}
            </div>
          )}
        </Paper>
      </DndContext>

      {showFeedback && (
        <p style={{ textAlign: 'center', marginTop: '2rem' }}>{feedback}</p>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: '2rem',
        }}>
        <Button variant='outlined' onClick={handleNext}>
          {button}
        </Button>
      </Box>
    </div>
  )
}

const Droppable = (props: any) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  })
  const style = {
    backgroundColor: isOver ? '#0091d4' : undefined,
  }

  return (
    <div className={styles.droppable} ref={setNodeRef} style={style}>
      {props.children}
    </div>
  )
}

const Draggable = (props: any) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  })
  const style = {
    transform: CSS.Translate.toString(transform),
    touchAction: 'none',
  }

  return (
    <button
      className={styles.draggable}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}>
      {props.children}
    </button>
  )
}

export default DragAndDrop
