import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { useState } from 'react'
import CheckboxList from './CheckboxList'
import styles from './ReadinessCheck.module.scss'

export interface IReadinessCheck {
  title: string
  questions: string[]
  feedback: {
    texts: string[]
  }
}

const ReadinessCheck = ({ data }: { data: IReadinessCheck }) => {
  const [selection, setSelection] = useState<number[]>([])
  const [state, setState] = useState<'question' | 'feedback'>('question')
  const [feedback, setFeedback] = useState('')

  // const handleSelection = (index: number) => {
  //   if (selection.includes(index)) {
  //     const selectionSet = new Set<number>()
  //     selection.forEach((item) => selectionSet.add(item))
  //     selectionSet.delete(index)
  //     const selectionArray = Array.from(selectionSet.values())
  //     return setSelection((previous) => selectionArray)
  //   }
  //   setSelection((previous) => [...previous, index])
  // }

  const handleToggle = (newSelection: number[]) => {
    setSelection(newSelection)
  }

  const handleClick = () => {
    setState('feedback')
    handleFeedback(selection.length)
  }

  const handleReset = () => {
    setState('question')
    setSelection([])
  }

  const handleFeedback = (score: number) => {
    switch (score) {
      case 6:
      case 5:
        setFeedback(data.feedback.texts[2])
        break
      case 4:
      case 3:
        setFeedback(data.feedback.texts[1])
        break
      case 2:
      case 1:
      case 0:
        setFeedback(data.feedback.texts[0])
        break

      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <h1>{data.title}</h1>
      {/* {data.questions.map((question, index) => (
        <div
          key={index}
          className={`${styles.question} ${
            selection.includes(index) ? styles.selected : ''
          }`}
          onClick={() => handleSelection(index)}>
          {question}
        </div>
      ))} */}
      {state === 'question' && (
        <>
          <CheckboxList data={data.questions} onToggle={handleToggle} />
          <Box
            sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <Button variant='outlined' onClick={handleClick}>
              Weiter
            </Button>
          </Box>
        </>
      )}
      {state === 'feedback' && (
        <>
          {/* <h2>Feedback</h2> */}
          <Paper
            elevation={4}
            sx={{ padding: '1rem', margin: '1rem', lineHeight: '1.4' }}>
            <p>{feedback}</p>
          </Paper>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <Button variant='outlined' onClick={handleReset}>
              Zurücksetzen
            </Button>
          </Box>
        </>
      )}
    </div>
  )
}

export default ReadinessCheck
