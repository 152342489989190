import { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemButton from '@material-ui/core/ListItemButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

export default function CheckboxList({
  data,
  onToggle,
}: {
  data: string[]
  onToggle: any
}) {
  const [checked, setChecked] = useState<number[]>([])

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    onToggle(newChecked)
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {data.map((value, index) => {
        const labelId = `checkbox-list-label-${index}`

        return (
          <ListItem key={value} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={handleToggle(index)}
              dense>
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={checked.indexOf(index) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value}
                className='.list-item'
              />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}
