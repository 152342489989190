import Quiz, { IQuizData } from '../components/Quiz'

const data: IQuizData = {
  title: 'Beeinflusst dieser Faktor die Kompetenzentwicklung stark?',
  options: {
    questionStyle: { textAlign: 'center' },
  },
  questions: [
    {
      question: 'Motivation',
      correct: true,
      answer:
        'Natürlich müssen wir sowohl eigene Motivation besitzen, als auch durch unsere Organisation motiviert werden, uns einer Veränderung zu stellen.',
    },
    {
      question: 'Durchsetzungsstärke',
      correct: false,
      answer:
        'Durchsetzungsstärke kann selbstverständlich in vielen anderen Situation sehr hilfreich sein, jedoch ist sie kein ausschlaggebender Faktor für die Kompetenzentwicklung.',
    },
    {
      question: 'Willenskraft',
      correct: true,
      answer:
        'Der Weg ist oft lang und steinig. Für jede Veränderung muss man sich auf einen längeren Prozess einstellen, der mal besser mal schlechter klappt. Es ist unsere Willenskraft die uns ans Ziel bringt.',
    },
    {
      question: 'Introversion',
      correct: false,
      answer:
        'Diese Persönlichkeitseigenschaft ist an und für sich weder positiv noch negativ. Es ist aber zugleich nicht wissenschaftlich belegt, dass sie eine Auswirkung auf Kompetenzentwicklung hat.',
    },
    {
      question: 'Soziale Bereitschaft',
      correct: true,
      answer:
        ' Der Mensch ist ein soziales Wesen. Wir werden stärker als wir denken auch von den Rahmenbedingungen unserer Welt zum Handeln beeinflusst. Daher fördert eine höhere soziale Bereitschaft auch die Entwicklung neuer Kompetenzen.',
    },
  ],
}
const Chapter4 = () => {
  return (
    <div className='i40-container'>
      <Quiz data={data} />
    </div>
  )
}

export default Chapter4
