import {
  closestCenter,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  Announcements,
  DndContext,
  DragOverlay,
  CollisionDetection,
  DropAnimation,
  LayoutMeasuring,
  Modifiers,
  PointerActivationConstraint,
  UniqueIdentifier,
  defaultDropAnimation,
} from '@dnd-kit/core'
import {
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  SortableContext,
  AnimateLayoutChanges,
  SortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import arrayMove from 'array-move'

import { Item, List, Wrapper } from './sortable/index'

export interface Props {
  activationConstraint?: PointerActivationConstraint
  animateLayoutChanges?: AnimateLayoutChanges
  adjustScale?: boolean
  collisionDetection?: CollisionDetection
  Container?: any // To-do: Fix me
  dropAnimation?: DropAnimation | null
  itemCount?: number
  items?: string[]
  handle?: boolean
  layoutMeasuring?: Partial<LayoutMeasuring>
  modifiers?: Modifiers
  renderItem?: any
  removable?: boolean
  strategy?: SortingStrategy
  useDragOverlay?: boolean
  getItemStyles?(args: {
    id: UniqueIdentifier
    index: number
    isSorting: boolean
    isDragOverlay: boolean
    overIndex: number
    isDragging: boolean
  }): React.CSSProperties
  wrapperStyle?(args: {
    index: number
    isDragging: boolean
    id: string
  }): React.CSSProperties
  isDisabled?(id: UniqueIdentifier): boolean
}

const defaultDropAnimationConfig: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
}

const defaultInitializer = (index: number) => index

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index))
}

export function Sortable({
  activationConstraint,
  animateLayoutChanges,
  adjustScale = false,
  Container = List,
  collisionDetection = closestCenter,
  dropAnimation = defaultDropAnimationConfig,
  getItemStyles = () => ({}),
  handle = false,
  itemCount = 1,
  items: initialItems,
  isDisabled = () => false,
  layoutMeasuring,
  modifiers,
  removable,
  renderItem,
  strategy = rectSortingStrategy,
  useDragOverlay = true,
  wrapperStyle = () => ({}),
}: Props) {
  const [items, setItems] = useState<string[]>(
    () =>
      initialItems ??
      createRange<string>(itemCount, (index) => (index + 1).toString())
  )
  const [activeId, setActiveId] = useState<string | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const getIndex = items.indexOf.bind(items)
  const getPosition = (id: string) => getIndex(id) + 1
  const activeIndex = activeId ? getIndex(activeId) : -1
  const handleRemove = removable
    ? (id: string) => setItems((items) => items.filter((item) => item !== id))
    : undefined
  const announcements: Announcements = {
    onDragStart(id) {
      return `Picked up sortable item ${id}. Sortable item ${id} is in position ${getPosition(
        id
      )} of ${items.length}`
    },
    onDragOver(id, overId) {
      if (overId) {
        return `Sortable item ${id} was moved into position ${getPosition(
          overId
        )} of ${items.length}`
      }

      return
    },
    onDragEnd(id, overId) {
      if (overId) {
        return `Sortable item ${id} was dropped at position ${getPosition(
          overId
        )} of ${items.length}`
      }

      return
    },
    onDragCancel(id) {
      return `Sorting was cancelled. Sortable item ${id} was dropped.`
    },
  }

  return (
    <DndContext
      announcements={announcements}
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }

        setActiveId(active.id)
      }}
      onDragEnd={({ over }) => {
        setActiveId(null)

        if (over) {
          const overIndex = getIndex(over.id)
          if (activeIndex !== overIndex) {
            setItems((items) => arrayMove(items, activeIndex, overIndex))
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      layoutMeasuring={layoutMeasuring}
      modifiers={modifiers}>
      <Wrapper center>
        <SortableContext items={items} strategy={strategy}>
          <Container>
            {items.map((value, index) => (
              <SortableItem
                key={value}
                id={value}
                handle={handle}
                index={index}
                style={getItemStyles}
                wrapperStyle={wrapperStyle}
                disabled={isDisabled(value)}
                renderItem={renderItem}
                onRemove={handleRemove}
                animateLayoutChanges={animateLayoutChanges}
                useDragOverlay={useDragOverlay}
              />
            ))}
          </Container>
        </SortableContext>
      </Wrapper>
      {useDragOverlay
        ? createPortal(
            <DragOverlay
              adjustScale={adjustScale}
              dropAnimation={dropAnimation}>
              {activeId ? (
                <Item
                  value={items[activeIndex]}
                  handle={handle}
                  renderItem={renderItem}
                  wrapperStyle={wrapperStyle({
                    index: activeIndex,
                    isDragging: true,
                    id: items[activeIndex],
                  })}
                  style={getItemStyles({
                    id: items[activeIndex],
                    index: activeIndex,
                    isSorting: activeId !== null,
                    isDragging: true,
                    overIndex: -1,
                    isDragOverlay: true,
                  })}
                  dragOverlay
                />
              ) : null}
            </DragOverlay>,
            document.body
          )
        : null}
    </DndContext>
  )
}

interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges
  disabled?: boolean
  id: string
  index: number
  handle: boolean
  useDragOverlay?: boolean
  onRemove?(id: string): void
  style(values: any): React.CSSProperties
  renderItem?(args: any): React.ReactElement
  wrapperStyle({
    index,
    isDragging,
    id,
  }: {
    index: number
    isDragging: boolean
    id: string
  }): React.CSSProperties
}

export function SortableItem({
  disabled,
  animateLayoutChanges,
  id,
  index,
  handle,
  onRemove,
  style,
  renderItem,
  useDragOverlay,
  wrapperStyle,
}: SortableItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    animateLayoutChanges,
    id,
    disabled,
  })

  return (
    <Item
      ref={setNodeRef}
      value={id}
      disabled={disabled}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      renderItem={renderItem}
      index={index}
      style={style({
        index,
        id,
        isDragging,
        isSorting,
        overIndex,
      })}
      onRemove={onRemove ? () => onRemove(id) : undefined}
      transform={transform}
      transition={!useDragOverlay && isDragging ? 'none' : transition}
      wrapperStyle={wrapperStyle({ index, isDragging, id })}
      listeners={listeners}
      data-index={index}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      {...attributes}
    />
  )
}
