import { Box, Button, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'
import styles from './Quiz.module.scss'

interface IQuestionData {
  question: string
  correct: boolean
  answer: string
}

interface IQuestionOptions {
  questionStyle?: object
}

export interface IQuizData {
  title: string
  questions: IQuestionData[]
  options?: IQuestionOptions
}

const Quiz = ({ data }: { data: IQuizData }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [feedback, setFeedback] = useState('')
  const [showFeedback, setShowFeedback] = useState(false)
  const [showOutro, setShowOutro] = useState(false)

  const getFeedback = (correct: boolean) => {
    if (!correct) {
      setFeedback(
        '<h3>Die richtige Antwort wäre:</h3>' +
          data.questions[currentQuestion].answer
      )
    } else {
      setFeedback('<h3>Richtig!</h3>')
    }
    setShowFeedback(true)
  }

  const handleNext = () => {
    if (currentQuestion === data.questions.length - 1) return setShowOutro(true)
    setShowFeedback(false)
    setCurrentQuestion((previous) => previous + 1)
  }

  const handleRestart = () => {
    setShowOutro(false)
    setShowFeedback(false)
    setCurrentQuestion(0)
  }

  return (
    <div>
      <h2>{data.title}</h2>
      {showOutro ? (
        <div>
          <p>Vielen Dank für Ihre Teilnahme!</p>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <Button
              variant='outlined'
              sx={{ marginRight: '1rem' }}
              onClick={handleRestart}>
              Quiz neu starten
            </Button>
          </Box>
        </div>
      ) : showFeedback ? (
        <div>
          <Paper
            elevation={4}
            sx={{ padding: '1rem', margin: '1rem', lineHeight: '1.4' }}>
            <p dangerouslySetInnerHTML={{ __html: feedback }} />
          </Paper>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <Button
              variant='outlined'
              sx={{ marginRight: '1rem', minWidth: '10rem' }}
              onClick={handleNext}>
              Weiter
            </Button>
          </Box>
        </div>
      ) : (
        <div className={styles.questions}>
          {data.questions.map((question, index) => (
            <QuizQuestion
              key={index}
              question={question}
              current={currentQuestion === index}
              onClick={getFeedback}
              style={data.options?.questionStyle}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const QuizQuestion = ({
  question,
  current,
  onClick,
  style,
}: {
  question: IQuestionData
  current: boolean
  onClick: any
  style?: any
}) => {
  const handleClick = (correct: boolean) => {
    return onClick(correct)
  }

  return (
    <div className={classNames(styles.question, !current && styles.hidden)}>
      <Paper
        elevation={4}
        sx={{ padding: '1rem', lineHeight: '1.4', ...style }}>
        <p>{question.question}</p>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
        <Button
          variant='outlined'
          sx={{ marginRight: '1rem', minWidth: '10rem', minHeight: '5rem' }}
          onClick={() => handleClick(question.correct === false)}>
          Falsch
        </Button>
        <Button
          variant='outlined'
          sx={{ minWidth: '10rem', minHeight: '5rem' }}
          onClick={() => handleClick(question.correct === true)}>
          Wahr
        </Button>
      </Box>
    </div>
  )
}

export default Quiz
