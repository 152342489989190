import { Box, Button, Paper } from '@material-ui/core'
import { useState } from 'react'
import { Sortable } from '../components/Sortable'

const items = [
  'Analytisches Denken',
  'Kreativität',
  'Kritisches Denken',
  'Programmierung',
  'Umgang mit Technologien',
  'Problemlösekompetenz',
  'Stresstoleranz',
  'Emotionale Intelligenz',
  'Systembewertung',
  'Überzeugungskunst',
]

const Chapter2 = () => {
  const [state, setState] = useState<'question' | 'feedback'>('question')

  const handleClick = () => {
    setState('feedback')
  }

  const handleReset = () => {
    setState('question')
  }

  return (
    <div className='i40-container'>
      {state === 'question' ? (
        <p>
          Das Weltwirtschaftsforum gibt regelmäßig Studien zu den wichtigsten
          Skills der Zukunft heraus. Einige davon finden Sie in der folgenden
          Liste. Doch sind alle gleichermaßen für Sie relevant? Um das
          herauszufinden, können Sie hier ein Ranking für sich erstellen:
        </p>
      ) : (
        <>
          <h2>Feedback</h2>
          <Paper
            elevation={4}
            sx={{ padding: '1rem', margin: '1rem', lineHeight: '1.4' }}>
            <p>
              Jetzt haben Sie wichtige Kompetenzen für die digitale Welt
              priorisiert. Nun kann die Arbeit richtig losgehen. Analysieren Sie
              für sich selbst oder für Ihre Belegschaft, inwiefern diese
              Kompetenzen schon vorhanden sind und welche Kompetenzen noch
              ausgebaut werden müssen. Das ist ein guter Startpunkt für
              systematisches Kompetenzmanagement.
            </p>
          </Paper>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <Button variant='outlined' onClick={handleReset}>
              Zurücksetzen
            </Button>
          </Box>
        </>
      )}
      {state === 'question' && (
        <>
          <Sortable items={items} />
          <Box
            sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <Button variant='outlined' onClick={handleClick}>
              Weiter
            </Button>
          </Box>
        </>
      )}
    </div>
  )
}

export default Chapter2
