import ReadinessCheck, { IReadinessCheck } from '../components/ReadinessCheck'

const data: IReadinessCheck = {
  title: 'Digitalisierung in der Berufsausbildung',
  questions: [
    'Ihre Lehrpläne sind schon heute auf die Anforderungen der VUCA Welt ausgerichtet.',
    'Ihre Ausbilderinnen und Ausbilder treten als Mentoren und Trainer auf.',
    'In der Ausbildung wird verstärkt Eigeninitiative und selbstständiges Lernen erwartet.',
    'In der Ausbildung kommt eine Mischung (digitaler) Lernformate zum Einsatz.',
    'Ihre Ausbildung hat eine adäquate digitale Ausstattung.',
    'Ihre Ausbilderinnen und Ausbilder bilden sich kontinuierlich weiter.',
  ],
  feedback: {
    texts: [
      'Ihre Berufsausbildung ist noch in der alten Welt gefangen. Jetzt gilt es erste Maßnahmen umzusetzen. Informieren Sie sich über die Anforderungen, die die Zukunft an uns stellt und trauen Sie sich loszulegen!',
      'Es ist schon einiges passiert, aber jetzt werden die nächsten Schritte benötigt. Denken Sie darüber nach, wie Sie Ihre Ausbilderinnen und Ausbilder befähigen sich in ihrer neuen Rollen bestmöglich einzufinden. Stellen Sie genügend digitale Lehrausstattung zur Verfügung.',
      'Ihre Berufsausbildung ist auf sehr gutem Wege, Menschen auch für die Zukunft auszubilden. Versuchen Sie dennoch kontinuierlich die Themen und Ihre Ausstattung zu aktualisieren und ermöglichen Sie, dass Ausbilderinnen und Ausbilder trotz Tagesgeschäft dauerhaft Neues lernen.',
    ],
  },
}

const Chapter6 = () => {
  return (
    <div className='i40-container'>
      <ReadinessCheck data={data} />
    </div>
  )
}

export default Chapter6
