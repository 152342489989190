import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Chapter1 from './pages/Chapter1'
import Chapter2 from './pages/Chapter2'
import Chapter3 from './pages/Chapter3'
import Chapter4 from './pages/Chapter4'
import Chapter5 from './pages/Chapter5'
import Chapter6 from './pages/Chapter6'

function App() {
  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route exact path='/chapter/1' component={Chapter1} />
          <Route exact path='/chapter/2' component={Chapter2} />
          <Route exact path='/chapter/3' component={Chapter3} />
          <Route exact path='/chapter/4' component={Chapter4} />
          <Route exact path='/chapter/5' component={Chapter5} />
          <Route exact path='/chapter/6' component={Chapter6} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
