import ReadinessCheck, { IReadinessCheck } from '../components/ReadinessCheck'

const data: IReadinessCheck = {
  title: 'Wie weit ist Ihr Unternehmen beim digitalen Lernen?',
  questions: [
    'Ist in Ihrem Unternehmen ein Learning Management System (LMS) vorhanden?',
    'Existieren digitale Lerninhalte in Ihrem Unternehmen (z.B. Erklärvideos, digitale Schulungsunterlagen)',
    'Werden in Ihrem Unternehmen Schulungen größtenteils online durchgeführt?',
    'Hat jeder Ihrer Mitarbeitenden Zugang zu einem PC oder zu einem mobilen Endgerät (Smartphone, Tablet)?',
    'Haben Mitarbeitende Zugriff zu Online-Lernplattformen wie Udacity, Haufe oder LinkedIn?',
    'Gibt es in Ihrem Unternehmen eine digitale Kollaborationsplattform, über die Mitarbeitende und Führungskräfte sich über Projekte und Verbesserungen austauschen können?',
  ],
  feedback: {
    texts: [
      'Digitales Lernen ist bei Ihnen noch in den Kinderschuhen. Jetzt gilt es erste Maßnahmen umzusetzen. Buchen Sie erste Online-Kurse für Ihre Mitarbeitende und schaffen Sie die Voraussetzungen für digitales Lernen, z.B. durch mobile Geräte.',
      'Es ist schon einiges passiert, aber jetzt werden die nächsten Schritte benötigt. Denken Sie über einen Weiterbildungs-Beauftragten nach und optimieren Sie die Bedingungen, z.B. durch eigene Lerninhalte oder die Anschaffung eines LMS.',
      'Es bestehen sehr gute Bedingungen für digitales Lernen. Hier und da kann es noch Verbesserungsmöglichkeiten geben. Versuchen Sie nun kontinuierlich, die Themen zu aktualisieren und ermöglichen Sie, dass Mitarbeitende trotz Tagesgeschäft dauerhaft Neues lernen.',
    ],
  },
}

const Chapter1 = () => {
  return (
    <div className='i40-container'>
      <ReadinessCheck data={data} />
    </div>
  )
}

export default Chapter1
