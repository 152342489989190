import DragAndDrop from '../components/DragAndDrop'

const Chapter5 = () => {
  return (
    <div className='i40-container'>
      <DragAndDrop />
    </div>
  )
}

export default Chapter5
