import Quiz, { IQuizData } from '../components/Quiz'

const data: IQuizData = {
  title:
    'Sie lernten gerade über vier Tipps, um das Lernen im Unternehmen zu fördern. Schaffen Sie es auf Anhieb zu sagen ob die Beschreibungen wahr oder falsch sind?',
  questions: [
    {
      question:
        'Reverse Mentoring: Man spricht von sog. ‚reverse mentoring‘, wenn es zu umgekehrten Lernrichtungen im Mentoring kommt: Man beginnt bewusst mit der schwierigsten Aufgabe, um den Lernenden die Tragweite und Ernsthaftigkeit des gelernten bewusst zu machen. Damit wird man auch auf den Zielzustand vorbereitet.',
      correct: false,
      answer:
        'Reverse Mentoring: Man spricht von sog. ‚reverse mentoring‘, wenn es zu umgekehrten Rollen im Mentoring kommt: Der Ausbilder erhält ein Training von einer Auszubildenden oder der Mitarbeiter zeigt seiner Chefin den Umgang mit einem neuen Tool. Das schafft eine Kultur des gegenseitigen Lernens, unabhängig von Rollen, Alter oder Hierarchie.',
    },
    {
      question:
        'Gemischte Teams: Vor allem wenn es um kreative Lösungen und Innovation geht, hat sich in Wissenschaft und Praxis bewiesen, dass diverse Teams einen Mehrwert stiften. Was hat das mit Lernen zu tun? Diverse Teams spornen mehr Wettbewerb innerhalb der Gruppe an. Der durchschnittliche Mensch strebt danach in der Hackordnung oben angesiedelt zu sein und möchte daher mehr Lernen als die Gruppenmitglieder.',
      correct: false,
      answer:
        'Gemischte Teams: Vor allem wenn es um kreative Lösungen und Innovation geht, hat sich in Wissenschaft und Praxis bewiesen, dass diverse Teams einen Mehrwert stiften. Was hat das mit Lernen zu tun? Es geht schlichtweg darum, über den Tellerrand zu blicken und sich auch mit Themen zu beschäftigen, die über den eigenen Aufgabenbereich hinaus gehen. Gemischte Teams unterstützen die Möglichkeit von- und miteinander zu lernen.',
    },
    {
      question:
        'Eine regelmäßige Rotation der Arbeitsaufgaben im Team erweitert den Horizont aller beteiligen Teammitglieder. Man wird automatisch in eine Situation gebracht, neue Inhalte erlernen zu müssen und gewöhnt sich so an eine kontinuierliche Lernkultur.',
      correct: true,
      answer:
        'Eine regelmäßige Rotation der Arbeitsaufgaben im Team erweitert den Horizont aller beteiligen Teammitglieder. Man wird automatisch in eine Situation gebracht, neue Inhalte erlernen zu müssen und gewöhnt sich so an eine kontinuierliche Lernkultur.',
    },
    {
      question:
        'Job Enlargement: Eine Alternative zur Erweiterung von Skills und passiv erzieltem Lernen ist das sog. ‚Job Enlargement‘. Mitarbeitende erhalten so schnell wie möglich umfassendere und vielfältigere Aufgaben. Es ist ausschlaggebend, dass diese nicht angelernt werden, um einen möglichst hohen Selbstlernerfolg zu erzielen. Diese Erweiterungen tragen dazu bei, sich niemals auf bestehenden Aufgaben auszuruhen und stattdessen kontinuierlich neue Themen und Aufgaben zu erlernen.',
      correct: false,
      answer:
        'Job Enlargement: Eine Alternative zur Erweiterung von Skills und passiv erzieltem Lernen ist das sog. ‚Job Enlargement‘. Mitarbeitende erhalten mit der Zeit umfassendere und vielfältigere Aufgaben. Diese Erweiterungen tragen dazu bei, sich niemals auf bestehenden Aufgaben auszuruhen und stattdessen kontinuierlich neue Themen und Aufgaben zu erlernen.',
    },
  ],
}
const Chapter3 = () => {
  return (
    <div className='i40-container'>
      <Quiz data={data} />
    </div>
  )
}

export default Chapter3
